export const setTokens = (accessToken, refreshToken) => {
  localStorage.setItem('access_token', accessToken);
  document.cookie = `refresh_token=${refreshToken}; path=/; SameSite=Lax; max-age=604800`; // 7 days
};

export const getAccessToken = () => {
  return localStorage.getItem('access_token');
};

export const getRefreshToken = () => {
  const cookies = document.cookie.split(';');
  const refreshTokenCookie = cookies.find(cookie => cookie.trim().startsWith('refresh_token='));
  return refreshTokenCookie ? refreshTokenCookie.split('=')[1] : null;
};

export const clearTokens = () => {
  localStorage.removeItem('access_token');
  document.cookie = 'refresh_token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
};

export const getCurrentUserInfo = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/user`, {
      method: 'GET',
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user info');
    }

    const data = await response.json();
    return data.user;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
};

/* Log out the user by clearing the token and making a logout request to the backend.
 */

export const logoutUser = async () => {
  try {
    await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/auth/sign_out`, {
      method: 'DELETE',
      credentials: 'include',
    });
    localStorage.removeItem('access_token');
  } catch (error) {
    console.error('Error logging out:', error);
  }
};

export const refreshAccessToken = async () => {
  try {
    if (refreshAccessToken.isRefreshing) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      return getAccessToken();
    }
    
    refreshAccessToken.isRefreshing = true;
    
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/auth/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh_token: refreshToken }),
      credentials: 'include',
    });

    if (response.ok) {
      const data = await response.json();
      setTokens(data.access_token, data.refresh_token);
      return data.access_token;
    } else {
      throw new Error('Failed to refresh token');
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    clearTokens();
    throw error;
  } finally {
    refreshAccessToken.isRefreshing = false;
  }
};

export const isAuthenticated = () => {
  return !!getAccessToken();
};
