import apiClient from './apiClient';

// Fetch all properties
export const fetchProperties = async () => {
  try {
    const response = await apiClient.get('/api/properties');
    return response.data.properties;
  } catch (error) {
    console.error('Error fetching properties:', error);
    throw error; // Re-throw the error so the calling code can handle it if needed
  }
};

// Fetch a single property by ID
export const fetchProperty = async (propertyId) => {
  try {
    const response = await apiClient.get(`/api/properties/${propertyId}`);
    return response.data.property;
  } catch (error) {
    console.error(`Error fetching property with ID ${propertyId}:`, error);
    throw error; // Re-throw the error so the calling code can handle it if needed
  }
};

// Create a new property
export const createProperty = async (propertyData) => {
  try {
    const formData = new FormData();

    // Append each property field to the form data
    for (const key in propertyData.property) {
      // Append only if not null or undefined
      if (propertyData.property[key] !== null && propertyData.property[key] !== undefined) {
        formData.append(`property[${key}]`, propertyData.property[key]);
      }
    }

    // Append managers and cleaners arrays separately
    propertyData.managers.forEach((managerId, index) => {
      formData.append(`managers[]`, managerId);
    });

    propertyData.cleaners.forEach((cleanerId, index) => {
      formData.append(`cleaners[]`, cleanerId);
    });

    const response = await apiClient.post('/api/properties', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.property;
  } catch (error) {
    console.error('Error creating property:', error);
    throw error; // Re-throw the error so the calling code can handle it if needed
  }
};

// Update an existing property
export const updateProperty = async (propertyId, propertyData) => {
  console.log('propertyData:', propertyData);
  try {
    let config = {};

    // Check if propertyData is an instance of FormData
    if (propertyData instanceof FormData) {
      config.headers = {
        'Content-Type': 'multipart/form-data',
      };
    }

    const response = await apiClient.put(`/api/properties/${propertyId}`, propertyData, config);
    return response.data.property;
  } catch (error) {
    console.error(`Error updating property with ID ${propertyId}:`, error);
    throw error;
  }
};

export const deleteProperty = async (propertyId) => {
  try {
    await apiClient.delete(`/api/properties/${propertyId}`);
    return true; // Successful deletion
  } catch (error) {
    console.error(`Error deleting property with ID ${propertyId}:`, error);
    throw error;
  }
};
