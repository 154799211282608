import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { setTokens } from '../../api/authUtils';

function Register({ navigatePath}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/auth/sign_up`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: { email, password } }),
        credentials: 'include',
      });
      const data = await response.json();
      if (response.ok) {
        console.log('Registration successful', data);

        // Automatically log in the user
        const loginResponse = await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/auth/sign_in`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user: { email, password } }),
          credentials: 'include',
        });

        const loginData = await loginResponse.json();
        if (loginResponse.ok) {
          setTokens(loginData.access_token, loginData.refresh_token);
          setUser(loginData.user); // Assuming your API returns user data
          navigate(navigatePath); // Navigate to the main page
        } else {
          setError(loginData.error || 'Unexpected error occurred during login');
        }
      } else {
        setError(data.error || 'Unexpected error occurred during registration');
      }
    } catch (error) {
      setError('Network error');
      console.error('Failed to register:', error);
    }
  };

  return (
    <div>
      <h1>Register</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleRegister}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Register</button>
      </form>
    </div>
  );
}

export default Register;
