// src/components/AuthWrapper.jsx
import React, { useEffect, useState } from 'react';
import { refreshAccessToken, isAuthenticated } from '../../api/authUtils';

const AuthWrapper = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      if (isAuthenticated()) {
        try {
          await refreshAccessToken();
        } catch (error) {
          console.error('Failed to refresh token:', error);
        }
      }
      setIsLoading(false);
    };

    initializeAuth();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
};

export default AuthWrapper;

