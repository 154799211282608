import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserInfo, logoutUser } from '../api/authUtils';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const fetchAndSetUser = async () => {
    const userInfo = await getCurrentUserInfo();
    setUser(userInfo);
  };

  useEffect(() => {
    fetchAndSetUser();
  }, []);

  const logout = async () => {
    try {
      await logoutUser();
      setUser(null);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, logout, fetchAndSetUser }}>
      {children}
    </UserContext.Provider>
  );
};
