import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaFileDownload, FaCircle } from 'react-icons/fa';
import styles from './Payouts.module.css';
import { format } from 'date-fns';

// Mock data - replace with API call later
const mockPayouts = [
  {
    id: 1,
    cleaner_name: "John Smith",
    period: "March 2024",
    total_amount: 2450.00,
    status: "paid",
    payment_method: "bank_transfer",
    payment_date: "2024-03-25",
    jobs_count: 15
  },
  {
    id: 2,
    cleaner_name: "Sarah Johnson",
    period: "March 2024",
    total_amount: 1890.50,
    status: "pending",
    payment_method: "stripe",
    payment_date: null,
    jobs_count: 12
  },
  {
    id: 3,
    cleaner_name: "Mike Wilson",
    period: "March 2024",
    total_amount: 3200.75,
    status: "draft",
    payment_method: null,
    payment_date: null,
    jobs_count: 18
  }
];

export default function Payouts() {
  const navigate = useNavigate();
  const [periodType, setPeriodType] = useState('month');
  const [selectedMonth, setSelectedMonth] = useState('March 2024');
  const [dateRange, setDateRange] = useState({
    from: '',
    until: ''
  });

  const [payouts, setPayouts] = useState([]);


  const getStatusColor = (status) => {
    switch (status) {
      case 'paid':
        return '#34D399';
      case 'pending':
        return '#FBBF24';
      case 'draft':
        return '#9CA3AF';
      default:
        return '#9CA3AF';
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const getLastTwelveMonths = () => {
    const months = [];
    const today = new Date();
    for (let i = 0; i < 12; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const formattedDate = format(date, 'MMMM yyyy');
      months.push(formattedDate);
    }
    return months;
  };

  const handlePeriodTypeChange = (type) => {
    setPeriodType(type);
    if (type === 'month') {
      setDateRange({ from: '', until: '' });
    } else {
      setSelectedMonth('');
    }
  };

  // Add this function to render mobile-friendly table rows
  const renderMobileTableRow = (payout) => (
    <div 
      key={payout.id}
      onClick={() => navigate(`/cleaning/payouts/${payout.id}`)}
      className={styles.mobileTableRow}
    >
      <div className={styles.mobileTableHeader}>
        <span>{payout.cleaner_name}</span>
        <span className={styles.status}>
          <FaCircle 
            color={getStatusColor(payout.status)} 
            size={8} 
          />
          {payout.status.charAt(0).toUpperCase() + payout.status.slice(1)}
        </span>
      </div>
      <div className={styles.mobileTableDetails}>
        <div>
          <label>Amount:</label>
          <span>{formatCurrency(payout.total_amount)}</span>
        </div>
        <div>
          <label>Jobs:</label>
          <span>{payout.jobs_count}</span>
        </div>
        <div>
          <label>Period:</label>
          <span>{payout.period}</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Payouts</h1>
        <div className={styles.actions}>
          <button 
            className={styles.exportButton}
            onClick={() => console.log('Export')}
          >
            <FaFileDownload /> Export
          </button>
          <button 
            className={styles.createButton}
            onClick={() => navigate('/cleaning/payouts/new')}
          >
            <FaPlus /> Create Payout
          </button>
        </div>
      </div>

      <div className={styles.periodSelector}>
        <div className={styles.periodTypeButtons}>
          <button
            className={`${styles.periodTypeButton} ${periodType === 'month' ? styles.active : ''}`}
            onClick={() => handlePeriodTypeChange('month')}
          >
            Month
          </button>
          <button
            className={`${styles.periodTypeButton} ${periodType === 'custom' ? styles.active : ''}`}
            onClick={() => handlePeriodTypeChange('custom')}
          >
            Custom Range
          </button>
        </div>
        
        {periodType === 'month' ? (
          <select 
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className={styles.periodSelect}
          >
            {getLastTwelveMonths().map(month => (
              <option key={month} value={month}>{month}</option>
            ))}
          </select>
        ) : (
          <div className={styles.dateRangeInputs}>
            <input
              type="date"
              value={dateRange.from}
              onChange={(e) => setDateRange(prev => ({ ...prev, from: e.target.value }))}
              className={styles.dateInput}
              placeholder="Start date"
            />
            <span className={styles.dateSeparator}>to</span>
            <input
              type="date"
              value={dateRange.until}
              onChange={(e) => setDateRange(prev => ({ ...prev, until: e.target.value }))}
              className={styles.dateInput}
              placeholder="End date"
            />
          </div>
        )}
      </div>

      <div className={styles.statsCards}>
        <div className={styles.statCard}>
          <h3>Total Payouts</h3>
          <p>{formatCurrency(7541.25)}</p>
          <span>This period</span>
        </div>
        <div className={styles.statCard}>
          <h3>Pending Payouts</h3>
          <p>{formatCurrency(1890.50)}</p>
          <span>Awaiting payment</span>
        </div>
        <div className={styles.statCard}>
          <h3>Total Jobs</h3>
          <p>45</p>
          <span>This period</span>
        </div>
        <div className={styles.statCard}>
          <h3>Average Per Job</h3>
          <p>{formatCurrency(7541.25 / 45)}</p>
          <span>This period</span>
        </div>
      </div>

      <div className={styles.tableContainer}>
        <div className={styles.desktopView}>
          <table className={styles.payoutsTable}>
            <thead>
              <tr>
                <th>Cleaner</th>
                <th>Period</th>
                <th>Jobs</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Payment Method</th>
                <th>Payment Date</th>
              </tr>
            </thead>
            <tbody>
              {mockPayouts.map((payout) => (
                <tr 
                  key={payout.id}
                  onClick={() => navigate(`/cleaning/payouts/${payout.id}`)}
                  className={styles.tableRow}
                >
                  <td>{payout.cleaner_name}</td>
                  <td>{payout.period}</td>
                  <td>{payout.jobs_count}</td>
                  <td>{formatCurrency(payout.total_amount)}</td>
                  <td>
                    <span className={styles.status}>
                      <FaCircle 
                        color={getStatusColor(payout.status)} 
                        size={8} 
                      />
                      {payout.status.charAt(0).toUpperCase() + payout.status.slice(1)}
                    </span>
                  </td>
                  <td>{payout.payment_method?.replace('_', ' ') || '-'}</td>
                  <td>{payout.payment_date || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.mobileView}>
          {mockPayouts.map(renderMobileTableRow)}
        </div>
      </div>
    </div>
  );
}
