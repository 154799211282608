import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProperty } from '../../../api/propertiesApi';
import { UserContext } from '../../../context/UserContext';
import ManagerPropertyCard from './ManagerPropertyCard';
import CleanerPropertyCard from './CleanerPropertyCard';

export default function PropertyDetails() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const [property, setProperty] = useState(null);

  useEffect(() => {
    const loadProperty = async () => {
      const fetchedProperty = await fetchProperty(id);
      setProperty(fetchedProperty);
    };
    loadProperty();
  }, [id]);

  if (!property) return <div>Loading...</div>;

  return (
    <div className="container-fluid p-0">
      <div className="property-content">
        {user.current_role === 'manager' ? (
          <ManagerPropertyCard property={property} />
        ) : (
          <CleanerPropertyCard property={property} />
        )}
      </div>
    </div>
  );
}
