import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import FilterDropdown from "../../../../../components/FilterDropdown/FilterDropdown";
import JobItem from '../JobItem/JobItem';
import SummaryStats from "../SummaryStats/SummaryStats";
import styles from './JobList.module.css';
import Select from 'react-select';
import JobDetails from '../../../../features/Calendar/JobDetails';
import { UserContext } from '../../../../../context/UserContext';

export default function JobList(props) {

  const [paymentFilter, setPaymentFilter] = React.useState('all');

  const [sortField, setSortField] = React.useState("date_of_job");
  const [sortOrder, setSortOrder] = React.useState("asc");

  const columns = [
    { label: "Payment Status", field: "payment" },
    { label: "Property Name", field: "property_title" },
    { label: props.userRole === "manager" ? "Cleaner" : "Manager", field: props.userRole === "manager" ? "cleaner_first_name" : "manager_first_name" },
    { label: "Date", field: "date_of_job" },
    { label: "Price", field: "price_cents" },
  ];

  const handleSortChange = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const sortJobs = (jobs) => {
    return [...jobs].sort((a, b) => {
      const valueA = a[sortField];
      const valueB = b[sortField];
      if (valueA === valueB) return 0;
      if (sortOrder === "asc") {
        return valueA < valueB ? -1 : 1;
      } else {
        return valueA > valueB ? -1 : 1;
      }
    });
  };

  const sortedJobs = sortJobs(props.jobs);

  // State to control the visibility of the filter dropdown
  const [showFilters, setShowFilters] = React.useState({});

  // Function to toggle the visibility of the filter dropdown for a specific field
  const toggleFilter = (field) => {
    setShowFilters((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  // Function to extract unique values from a field in the jobs array
  const extractUniqueValues = (jobs, field) => {
    return [...new Set(jobs.map(job => {
      if (field === "price_cents") {
        return (job[field] / 100).toFixed(2);
      } else {
        return job[field];
      }
    }))];
  };

  // Generate options for the filter dropdown
  const options = columns.map(col => {
    return {
      value: col.field,
      label: col.label,
      options: extractUniqueValues(sortedJobs, col.field).map(value => ({
        value,
        label: value
      }))
    };
  });


  // State to store the selected filter options for each field
  const [filterOptions, setFilterOptions] = React.useState({});

  // Handle filter change
  const handleFilterChange = (selectedOptions, field) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      // Clear filter for the specified field
      setFilterOptions((prev) => {
        const updatedFilters = { ...prev };
        delete updatedFilters[field];
        return updatedFilters;
      });
    } else {
      setFilterOptions((prev) => ({
        ...prev,
        [field]: selectedOptions,
      }));
    }
  };

  // Add payment filter logic
  const paymentFilteredJobs = sortedJobs.filter(job => {
    if (paymentFilter === 'all') return true;
    if (paymentFilter === 'paid') return job.paid === true;
    if (paymentFilter === 'unpaid') return job.paid === false;
    return true;
  });

  // Update the final filtered jobs to include payment filter
  const filteredJobs = paymentFilteredJobs.filter(job => {
    return Object.keys(filterOptions).every(field => {
      const selectedValues = filterOptions[field].map(option => option.value);
      if (field === "price_cents") {
        return selectedValues.includes((job[field] / 100).toFixed(2));
      } else {
        return selectedValues.includes(job[field]);
      }
    });
  });

  const renderedJobs = filteredJobs.map((job, index) => (
      <JobItem key={index} job={job} columns={columns} /> // Using JobItem component
  ));


  React.useEffect(() => {
    props.setFieldFilteredJobs(filteredJobs);
  }, [filterOptions, props.from, props.until]); // eslint-disable-line react-hooks/exhaustive-deps

  // Add new state for filter modal
  const [isFilterModalOpen, setIsFilterModalOpen] = React.useState(false);

  // Add toggle function for filter modal
  const toggleFilterModal = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  // Filter out the payment status from columns shown in filter modal
  const filterableColumns = columns.filter(col => col.field !== "payment");

  // Format options for react-select
  const getOptionsForField = (field) => {
    return extractUniqueValues(sortedJobs, field).map(value => ({
      value,
      label: value
    }));
  };

  const { user } = React.useContext(UserContext);
  const [selectedJob, setSelectedJob] = React.useState(null);
  const [isJobDetailsModalOpen, setIsJobDetailsModalOpen] = React.useState(false);

  const handleJobClick = (job) => {
    setSelectedJob(job.id);
    setIsJobDetailsModalOpen(true);
  };

  const closeJobDetailsModal = (e) => {
    if (!e || e.target.classList.contains(styles.modalOverlay)) {
      setIsJobDetailsModalOpen(false);
      setSelectedJob(null);
    }
  };

  return (
    <>
      <SummaryStats jobs={filteredJobs}/>
      
      <div className={styles.cardContainer}>
        {/* Add filter button next to the tabs */}
        <div className={styles.headerControls}>
          <div className={styles.tabContainer}>
            <button 
              className={`${styles.tab} ${paymentFilter === 'all' ? styles.activeTab : ''}`}
              onClick={() => setPaymentFilter('all')}
            >
              All
            </button>
            <button 
              className={`${styles.tab} ${paymentFilter === 'paid' ? styles.activeTab : ''}`}
              onClick={() => setPaymentFilter('paid')}
            >
              Paid
            </button>
            <button 
              className={`${styles.tab} ${paymentFilter === 'unpaid' ? styles.activeTab : ''}`}
              onClick={() => setPaymentFilter('unpaid')}
            >
              Not Paid
            </button>
          </div>
          <button onClick={toggleFilterModal} className={styles.filterButton}>
            <FontAwesomeIcon icon={faFilter} /> Filter
          </button>
        </div>

        {/* Updated Filter Modal with Multi-select */}
        {isFilterModalOpen && (
          <div className={styles.filterModalOverlay}>
            <div className={styles.filterModalContent}>
              <h2>Filters</h2>
              <div className={styles.filtersContainer}>
                {filterableColumns.map((col) => (
                  <div key={col.field} className={styles.filterItem}>
                    <label htmlFor={col.field}>{col.label}:</label>
                    <Select
                      id={col.field}
                      isMulti
                      value={filterOptions[col.field] || []}
                      onChange={(selected) => handleFilterChange(selected, col.field)}
                      options={getOptionsForField(col.field)}
                      placeholder={`Select ${col.label}(s)...`}
                      className={styles.multiSelect}
                      classNamePrefix="select"
                      isClearable={true}
                    />
                  </div>
                ))}
              </div>
              <button onClick={toggleFilterModal} className={styles.closeButton}>
                Close
              </button>
            </div>
          </div>
        )}

        {/* Desktop View */}
        <div className={styles.desktopList}>
          <div className={styles.headerRow}>
            {columns.map((col, index) => (
              <div key={index} className={styles.headerCell}>
                {col.label}
              </div>
            ))}
          </div>
          {filteredJobs.map((job, index) => (
            <div key={index} className={styles.jobRow}>
              <div>{job.payment ? 'Paid' : 'Not Paid'}</div>
              <div>{job.property_title}</div>
              <div>{job[props.userRole === "manager" ? "cleaner_first_name" : "manager_first_name"]}</div>
              <div>{new Date(job.date_of_job).toLocaleDateString()}</div>
              <div>€ {(job.price_cents / 100).toFixed(2)}</div>
              <button className={styles.detailsButton} onClick={() => handleJobClick(job)}>Details</button>
            </div>
          ))}
        </div>

        {/* Mobile View */}
        <div className={styles.mobileList}>
          {filteredJobs.map((job, index) => (
            <div key={index} className={styles.jobCard}>
              <div className={`${styles.jobStatus} ${job.payment ? styles.paid : ''}`}>
                {job.payment ? 'Paid' : 'Not Paid'}
              </div>
              <div className={styles.propertyName}>{job.property_title}</div>
              <div className={styles.jobDetail}>
                <span>Cleaner:</span>
                <span>{job[props.userRole === "manager" ? "cleaner_first_name" : "manager_first_name"]}</span>
              </div>
              <div className={styles.jobDetail}>
                <span>Date:</span>
                <span>{new Date(job.date_of_job).toLocaleDateString()}</span>
              </div>
              <div className={styles.price}>
                € {(job.price_cents / 100).toFixed(2)}
              </div>
              <button className={styles.detailsButton} onClick={() => handleJobClick(job)}>Details</button>
            </div>
          ))}
        </div>
      </div>
      {isJobDetailsModalOpen && selectedJob && (
        <div className={styles.modalOverlay} onClick={closeJobDetailsModal}>
          <div className={styles.modalContent}>
            <JobDetails 
              jobId={selectedJob} 
              onClose={() => closeJobDetailsModal()} 
              user={user}
              onJobDeleted={(deletedJobId) => {
                closeJobDetailsModal();
                props.onJobDeleted(deletedJobId);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
