import apiClient from './apiClient';

export const createPayoutRule = async (data) => {
    try {
        const response = await apiClient.post('/api/payout_rule_configurations', {
            payout_rule_type_id: data.payout_rule_type_id,
            cleaner_id: data.cleaner_id,
            configuration: data.configuration
        });

        return response.data;
    } catch (error) {
        console.error('Error creating payout rule:', error);
        throw error;
    }
};

export const updatePayoutRule = async (ruleId, data) => {
    try {
        const response = await apiClient.put(`/api/payout_rule_configurations/${ruleId}`, {
            payout_rule_type_id: data.payout_rule_type_id,
            cleaner_id: data.cleaner_id,
            configuration: data.configuration
        });

        return response.data;
    } catch (error) {
        console.error('Error updating payout rule:', error);
        throw error;
    }
};

export const fetchPayoutRules = async (cleanerId) => {
    try {
        const response = await apiClient.get(`/api/payout_rule_configurations`, {
            params: {
                cleaner_id: cleanerId
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching payout rules:', error);
        throw error;
    }
};

export const deletePayoutRule = async (ruleId) => {
    try {
        const response = await apiClient.delete(`/api/payout_rule_configurations/${ruleId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting payout rule:', error);
        throw error;
    }
};