import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';

// Use the correct environment variable name
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_KEY;

if (!MAPBOX_TOKEN) {
  console.error('Mapbox token is missing. Please check your environment variables.');
}

mapboxgl.accessToken = MAPBOX_TOKEN;

const MapComponent = ({ address, onAddressChange }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [zoom, setZoom] = useState(2);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom,
      geocoderControl: false
    });

    // Add geocoding control
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false
    });

    // Add navigation control
    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    // Add marker
    marker.current = new mapboxgl.Marker()
      .setLngLat([lng, lat])
      .addTo(map.current);

    // Geocode the initial address
    if (address) {
      geocodeAddress(address);
    }

    // Listen for the 'result' event from the geocoder
    geocoder.on('result', (e) => {
      const coords = e.result.center;
      setLng(coords[0]);
      setLat(coords[1]);
      marker.current.setLngLat(coords);
      map.current.flyTo({ center: coords, zoom: 14 });
      onAddressChange(e.result.place_name);
    });

    // Update marker position on map click
    map.current.on('click', (e) => {
      setLng(e.lngLat.lng);
      setLat(e.lngLat.lat);
      marker.current.setLngLat(e.lngLat);
      reverseGeocode(e.lngLat);
    });
  }, []);

  const geocodeAddress = (address) => {
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${MAPBOX_TOKEN}`)
      .then(response => response.json())
      .then(data => {
        if (data.features && data.features.length > 0) {
          const coords = data.features[0].center;
          setLng(coords[0]);
          setLat(coords[1]);
          marker.current.setLngLat(coords);
          map.current.flyTo({ center: coords, zoom: 14 });
        }
      })
      .catch(error => console.error('Error geocoding address:', error));
  };

  const reverseGeocode = (lngLat) => {
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?access_token=${MAPBOX_TOKEN}`)
      .then(response => response.json())
      .then(data => {
        if (data.features && data.features.length > 0) {
          onAddressChange(data.features[0].place_name);
        }
      })
      .catch(error => console.error('Error reverse geocoding:', error));
  };

  useEffect(() => {
    if (address && map.current) {
      geocodeAddress(address);
    }
  }, [address]);

  return <div ref={mapContainer} style={{ width: '100%', height: '300px' }} />;
};

export default MapComponent;
