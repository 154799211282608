import React, { useState, useEffect } from 'react';
import styles from './RatingBonusModal.module.css';

export default function RatingBonusModal({ 
  isOpen, 
  onClose, 
  onSave, 
  initialValues = null 
}) {
  const [formData, setFormData] = useState({
    actualRating: '',
    thresholdRating: '4.8',
    bonusPerPoint: '',
    calculatedBonus: 0
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
    }
  }, [initialValues]);

  const validateInputs = () => {
    const newErrors = {};
    
    if (!formData.actualRating) {
      newErrors.actualRating = 'Required';
    } else if (formData.actualRating < 1 || formData.actualRating > 5) {
      newErrors.actualRating = 'Must be between 1 and 5';
    }

    if (!formData.thresholdRating) {
      newErrors.thresholdRating = 'Required';
    } else if (formData.thresholdRating < 1 || formData.thresholdRating > 5) {
      newErrors.thresholdRating = 'Must be between 1 and 5';
    }

    if (parseFloat(formData.actualRating) <= parseFloat(formData.thresholdRating)) {
      newErrors.actualRating = 'Must be higher than threshold';
    }

    if (!formData.bonusPerPoint) {
      newErrors.bonusPerPoint = 'Required';
    } else if (formData.bonusPerPoint <= 0) {
      newErrors.bonusPerPoint = 'Must be greater than 0';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => {
      const newData = {
        ...prev,
        [name]: value
      };
      
      // Calculate bonus whenever inputs change
      if (newData.actualRating && newData.thresholdRating && newData.bonusPerPoint) {
        const difference = Math.max(0, newData.actualRating - newData.thresholdRating);
        const pointsAboveThreshold = (difference * 10);
        newData.calculatedBonus = pointsAboveThreshold * newData.bonusPerPoint;
      }

      return newData;
    });
  };

  const handleSubmit = () => {
    if (validateInputs()) {
      onSave(formData);
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h3>Configure Rating Bonus</h3>
          <button 
            className={styles.closeButton}
            onClick={onClose}
            aria-label="Close"
          >
            ×
          </button>
        </div>

        <div className={styles.modalContent}>
          <div className={styles.formGroup}>
            <label>
              Average Rating
              <span className={styles.tooltip} title="Enter the average rating for selected jobs">ⓘ</span>
            </label>
            <input
              type="number"
              name="actualRating"
              value={formData.actualRating}
              onChange={handleChange}
              className={`${styles.input} ${errors.actualRating ? styles.inputError : ''}`}
              min="1"
              max="5"
              step="0.1"
              placeholder="Enter rating (1-5)"
            />
            {errors.actualRating && (
              <span className={styles.errorText}>{errors.actualRating}</span>
            )}
          </div>

          <div className={styles.formGroup}>
            <label>
              Target Rating Threshold
              <span className={styles.tooltip} title="Minimum rating required for bonus">ⓘ</span>
            </label>
            <input
              type="number"
              name="thresholdRating"
              value={formData.thresholdRating}
              onChange={handleChange}
              className={`${styles.input} ${errors.thresholdRating ? styles.inputError : ''}`}
              min="1"
              max="5"
              step="0.1"
              placeholder="e.g., 4.8"
            />
            {errors.thresholdRating && (
              <span className={styles.errorText}>{errors.thresholdRating}</span>
            )}
          </div>

          <div className={styles.formGroup}>
            <label>
              Bonus per 0.1 Point (€)
              <span className={styles.tooltip} title="Amount awarded for each 0.1 point above threshold">ⓘ</span>
            </label>
            <input
              type="number"
              name="bonusPerPoint"
              value={formData.bonusPerPoint}
              onChange={handleChange}
              className={`${styles.input} ${errors.bonusPerPoint ? styles.inputError : ''}`}
              min="0"
              step="0.01"
              placeholder="e.g., 2"
            />
            {errors.bonusPerPoint && (
              <span className={styles.errorText}>{errors.bonusPerPoint}</span>
            )}
          </div>

          {formData.calculatedBonus > 0 && (
            <div className={styles.previewBox}>
              <h4>Preview Calculation</h4>
              <p>Rating difference: {Math.max(0, (formData.actualRating - formData.thresholdRating)).toFixed(1)} points</p>
              <p>Total bonus: €{formData.calculatedBonus.toFixed(2)}</p>
            </div>
          )}
        </div>

        <div className={styles.modalFooter}>
          <button 
            className={styles.secondaryButton}
            onClick={onClose}
          >
            Cancel
          </button>
          <button 
            className={styles.primaryButton}
            onClick={handleSubmit}
          >
            Save Bonus Configuration
          </button>
        </div>
      </div>
    </div>
  );
}
