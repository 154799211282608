import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { fetchProperties } from '../../../api/propertiesApi';
import { Link } from 'react-router-dom';

const PropertiesIndex = () => {
  const { user } = useContext(UserContext);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const getProperties = async () => {
      try {
        const data = await fetchProperties();
        setProperties(data);
      } catch (error) {
        console.error('Failed to fetch properties', error);
      }
    };

    getProperties();
  }, []);

  if (!user) return <p>Loading...</p>;

  const propertyList = (props) => {
    return (
      <div>
        {props.map(property => (
          <div key={property.id}>
            <Link to={`/cleaning/properties/${property.id}`}>
              {property.title}
            </Link>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <h1>{user.current_role === 'manager' ? 'Your Properties' : 'Properties you are cleaning'}</h1>
      {propertyList(properties)}
      {user.current_role === 'manager' && (
        <div>
          <Link to="/cleaning/properties/new" className="btn btn-primary">Add Property</Link>
        </div>
      )}
    </div>
  );
};

export default PropertiesIndex;
