import React, { useContext } from "react"
import { UserContext } from "../../../../../context/UserContext"
import styles from "./SummaryStats.module.css"

export default function SummaryStats({ jobs }) {
  const { user } = useContext(UserContext);

  function getNumberOfJobs(jobsList, filterFn = () => true) {
    return jobsList.filter(filterFn).length;
  }

  function getTotalPrice(jobsList, filterFn = () => true) {
    return jobsList.filter(filterFn).reduce((acc, job) => {
      const currency = job.price_currency;
      const price = job.price_cents / 100;
      if (acc[currency]) {
        acc[currency] += price;
      } else {
        acc[currency] = price;
      }
      return acc;
    }, {});
  }

  function formatTotalPrices(totals) {
    return Object.entries(totals).map(([currency, total], index) => (
      <span key={index} className={styles.price}>
        {total.toFixed(2)} {currency}
      </span>
    ));
  }

  // Get unique cleaners with proper filtering
  const cleaners = [...new Set(jobs
    .filter(job => job.cleaner_first_name || job.cleaner_last_name)
    .map(job => `${job.cleaner_first_name} ${job.cleaner_last_name}`)
    .sort())];

  // Get unique properties
  const properties = [...new Set(jobs
    .filter(job => job.property_title)
    .map(job => job.property_title)
    .sort())];

  return (
    <div className={styles.card}>
      <div className={styles.overallStats}>
        <div className={styles.statBox}>
          <div className={styles.statIcon}>📊</div>
          <span className={styles.statLabel}>Total Jobs</span>
          <span className={styles.statValue}>{getNumberOfJobs(jobs)}</span>
        </div>
        <div className={styles.statBox}>
          <div className={styles.statIcon}>💰</div>
          <span className={styles.statLabel}>Total Price</span>
          <span className={styles.statValue}>
            {formatTotalPrices(getTotalPrice(jobs))}
          </span>
        </div>
      </div>

      {user && user.current_role !== "cleaner" && (
        <section className={styles.statsSection}>
          <h2 className={styles.sectionTitle}>
            <span className={styles.titleIcon}>👥</span>
            Cleaner Statistics
          </h2>
          <div className={styles.statsList}>
            {cleaners.map(cleaner => (
              <div key={cleaner} className={styles.listItem}>
                <div className={styles.listHeader}>
                  <div className={styles.avatarCircle}>
                    {cleaner.charAt(0)}
                  </div>
                  <h3 className={styles.listItemTitle}>{cleaner}</h3>
                </div>
                <div className={styles.listStats}>
                  <div className={styles.statBadge}>
                    <span className={styles.badgeLabel}>Jobs</span>
                    <span className={styles.badgeValue}>
                      {getNumberOfJobs(jobs, job => 
                        `${job.cleaner_first_name} ${job.cleaner_last_name}` === cleaner
                      )}
                    </span>
                  </div>
                  <div className={styles.statBadge}>
                    <span className={styles.badgeLabel}>Price</span>
                    <span className={styles.badgeValue}>
                      {formatTotalPrices(getTotalPrice(jobs, job => 
                        `${job.cleaner_first_name} ${job.cleaner_last_name}` === cleaner
                      ))}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}

      <section className={styles.statsSection}>
        <h2 className={styles.sectionTitle}>
          <span className={styles.titleIcon}>🏠</span>
          Property Statistics
        </h2>
        <div className={styles.statsList}>
          {properties.map(property => (
            <div key={property} className={styles.listItem}>
              <div className={styles.listHeader}>
                <div className={styles.propertyIcon}>🏠</div>
                <h3 className={styles.listItemTitle}>{property}</h3>
              </div>
              <div className={styles.listStats}>
                <div className={styles.statBadge}>
                  <span className={styles.badgeLabel}>Cleanings</span>
                  <span className={styles.badgeValue}>
                    {getNumberOfJobs(jobs, job => job.property_title === property)}
                  </span>
                </div>
                <div className={styles.statBadge}>
                  <span className={styles.badgeLabel}>Price</span>
                  <span className={styles.badgeValue}>
                    {formatTotalPrices(getTotalPrice(jobs, job => job.property_title === property))}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}
